<template>
  <div v-nk-loading="allLoading">
    <div class="bannerData d-flex w-100 justify-space-between bg-color-white box-show-bottom-ccc" v-if="isDynamic">
      <div class="adaptive-width-next d-flex justify-space-between pad-bot-5">
        <div class="dynamicList">
          <div v-for="(item,index) in allTypes()" :class="{activeStyle:index==isActive}" :key="index"
               class="statusData cursor-pointer" @click="handleClick(String(index))">{{ item.typeName }}
          </div>
        </div>
        <el-input :placeholder="$t('news.pleaseEnterContent')" prefix-icon="el-icon-search" class="select-wrap"
                  v-model="title" @keyup.enter="getValueKey"></el-input>
      </div>
    </div>
    <div class="news-wrap page-width min-pad-left-right-10">
      <div class="news-main">
        <div class="banner">
          <div class="swiper-div">
            <swiper ref="mySwiper" v-if="brandArr.length>0" class="swiper-container requireSwiper"
                    :options="swiperOptions">
              <div class="swiper-slide cursor-pointer swiper-active-hover" v-for="(item) in brandArr" :key="item.id"
                   @click="editDetails(item)">
                <img class="lun-img" :src="item.carousel_img" alt="carouselImg">
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="d-flex justify-space-between" >
                  <h3 style="width:100%;text-align:center;font-size:16px;" class="mar-15-0 mon-text-overflow-1 swiper-title" v-html="item[getLangType('title')]"></h3>
                </div>
                <div cl ass="mon-text-overflow-1" style="text-align:center;font-size:14px;">{{ item[getLangType('introduction')] }}</div>
              </div>
              <div class="jumpButton-next swiper-left swiper-dis swiper-button-prev" slot="button-prev">
                <span class="iconfont pad-15 fs-20 bg-color-ori color-fff">&#xe600;</span>
              </div>
              <div class="jumpButton-next swiper-right swiper-dis swiper-button-next" slot="button-next">
                <span class="iconfont pad-15 fs-20 bg-color-ori color-fff">&#xe601;</span>
              </div>
            </swiper>
          </div>
        </div>
        <div ref="topDistance">
          <el-tabs v-model="activeName" v-loading="loading" @tab-click="handleClick(activeName)">
            <el-tab-pane :label="item.typeName" v-for="(item, index) in allTypes()" :key="index">
              <div class="news-list-main" v-if="dynamicList.length>0">
                <div class="article-item" v-for="(item, index) in dynamicList" :key="index" @click="editDetails(item)">
                  <div class="article-img">
                    <img class="cover-img" v-real-img="item.image" src="" alt=""/>
                  </div>
                  <div class="article-container article-demo">
                    <div>
                      <p class="title-box">
                        <span class="article-tag" v-if="item.selection === 1">{{ $t('news.featured') }}</span>
                        <span class="mon-text-overflow-2" style="font-size:16px;">{{ item[getLangType('title')] }}</span>
                      </p>
                      <p class="content" style="font-size:14px;">{{ item[getLangType('introduction')] }}</p>
                    </div>
                    <div class="info">
                      <div class="publish-time column">{{ item.created_at }}</div>
                      <!-- <div class="origin">{{ item.category_name }}</div> -->
                      <div class="column">{{ item.source }}</div>
                      <div class="column">{{ allTypes(item.category_id) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <el-empty v-else :image-size="200"></el-empty>
            </el-tab-pane>
          </el-tabs>
          <div class="d-flex justify-content-center pad-20">
            <el-pagination background @current-change="handleCurrentChange" :pager-count="5" :current-page="page"
                           :page-size="limit" layout="prev, pager, next" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="select-wrap">
        <el-input :placeholder="$t('news.pleaseEnterContent')" prefix-icon="el-icon-search" class="search-enter-wrap"
                  v-model="title" @keyup.enter.native="getValueKey()">
        </el-input>
        <div class="select-wrap-title">
          <span class="iconfont fs-38 color-ori">&#xe7a8;</span>
          <span class="fs-26 font-w-bold white-space-nowrap">{{ $t('news.editFeatured') }}</span>
        </div>
        <div @click="editDetails(item)" v-for="(item, index) in editSelect" :key="index">
          <div class="d-flex padBottomData">
            <div class="select-wrap-title number-text-Data">
              <span class="number-text">{{ index + 1 >= 10 ? index + 1 : '0' + (index + 1) }}</span>
            </div>
            <div class="select-item-content">
              <h2 class="select-title">
                <p class="title-text">{{ item[getLangType('title')] }}</p>
              </h2>
              <div class="select-item-info">
                <div class="publish-time white-space-nowrap">{{ item.created_at }}</div>
                <div class="column" style="width: 240px">{{ allTypes(item.category_id) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-module home-banner">
      <div class="home-module-word">
        <div class="home-module-title">
          <h2 class="home-module-title">{{ $t('home.model6Title') }}</h2>
        </div>
        <button class="cta cursor-pointer" @click="toSettled()">
          <span class="hover-underline-animation">{{ $t('home.moveInNow') }}</span>
          <i class="el-icon-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      activeName: '0',
      title: '',
      dynamicList: [],
      editSelect: [],
      brandArr: [],
      // allTypes: ,
      page: 1,
      limit: 20,
      total: null,
      allLoading: false,
      isActive: 0,
      isDynamic: false,
      swiperOptions: {
        slidesPerView: 1, // 显示个数
        slidesPerGroup: 1,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        autoplay: {
          disableOnInteraction: false,  // 用户操作swiper之后，是否禁止autoplay
          delay: 3000, // 自动切换的时间间隔（单位ms）
        },
        pagination: {
          el: '.swiper-pagination'
        },
        paginationClickable: true,
        direction: 'horizontal'
      },
    }
  },
  mounted() {
    this.handleClick('0')
    this.getValueKey()
    window.addEventListener('scroll', this.handleScroll, true);
  },
  computed: {
    getLangType() {
      return function (key) {
        switch (this.$i18n.locale) {
          case 'en_US':
            return 'en_' + key
          case 'zh_CN':
            return key
          default:
            return key
        }
      }
    },
    allTypes() {
      return function (value) {
        const list = [
          {value: '', typeName: this.$t('newStatus.allStatus')},
          {value: 1, typeName: this.$t('newStatus.monmallNews')},
          {value: 2, typeName: this.$t('newStatus.marketInsight')},
          {value: 3, typeName: this.$t('newStatus.operatingDryGoods')},
          // {value: 4, typeName: this.$t('newStatus.monmallActive')},
        ]
        if (value) {
          return list.filter(item => item.value === value)[0].typeName
        } else {
          return list
        }
      }
    }
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (this.$refs.topDistance.getBoundingClientRect().top < 100) {
        this.isDynamic = true
      } else {
        this.isDynamic = false
      }
    },
    handleClick(value) {
      this.isActive = value
      value ? this.activeName = value : this.activeName
      this.loading = true
      const data = {
        category_id: this.activeName === '0' ? '' : this.activeName,
        page: this.page,
      }
      this.$api.newsList(data).then(res => {
        this.dynamicList = res.data
        this.total = res.meta.pagination.total
        this.limit = res.meta.pagination.per_page
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 选择当前页数
    handleCurrentChange(val) {
      this.page = Number(`${val}`)
      this.handleClick(this.isActive)
    },
    getValueKey() {
      this.allLoading = true
      const data = {
        title: this.title
      }
      this.$api.newInfo(data).then(res => {
        this.brandArr = res.data.recommend
        this.editSelect = res.data.selection
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },
    editDetails(item) {
      this.$router.push('/newData?id=' + item.id)
    },
    prev(index) {
      this.$refs.mySwiper.$swiper.slidePrev(index)
    },
    next(index) {
      this.$refs.mySwiper.$swiper.slideNext(index)
    },
    toSettled() {
      this.$router.push({path: '/settled'})
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true)
  }
}
</script>

<style scoped>
.bannerData .el-tabs__header {
  margin-bottom: 0 !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none !important;
}

.swiper-div >>> .el-carousel__arrow {
  display: none !important;
}

.swiper-div {
  width: 800px;
}

.adaptive-width {
  width: 100% !important;
}

.adaptive-width-next {
  width: 63%;
}

.bannerData {
  justify-content: space-around;
}

.lun-img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.el-tabs >>> .is-active {
  color: #4285f7 !important;
}

.el-tabs >>> .el-tabs__item:hover {
  color: #4285f7 !important;
}

.el-tabs >>> .el-tabs__active-bar {
  background-color: #4285f7;
}

.activeStyle {
  color: #4285f7 !important;
}

.requireSwiper {
  position: relative;
}

.swiper-left {
  position: absolute;
  z-index: 1000;
  left: 0;
  background-color: #fcfcfc;
}

.swiper-right {
  position: absolute;
  right: 20px;
  z-index: 1000;
  background-color: #fcfcfc;
}

.swiper-dis {
  display: none;
}

.requireSwiper:hover .swiper-dis {
  display: inline-block;
}

.swiper-active-hover:hover .swiper-title {
  color: #4285f7;
}

.swiper-pagination {
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

@media screen and (max-width: 997px) {
  .news-wrap {
    flex-wrap: wrap;
    padding-top: 50px;
  }

  .page-width {
    width: auto;
  }

  .news-main {
    width: 100%;
  }

  .swiper-div {
    width: 100%;
  }

  .banner {
    margin-bottom: 20px;
  }

  .select-wrap {
    display: none;
  }

  .mar-top-60 {
    margin-top: 50px;
  }

  .bannerData {
    display: none !important;
  }

  .lun-img {
    height: 150px;
  }

  .news-list-main {
    width: 100%;
  }

  .article-img {
    margin-right: 10px;
    height: 80px;
  }

  .cover-img {
    width: 102px;
    height: 68px;
    object-fit: cover;
  }

  .article-item {
    margin-bottom: 40px;
    height: 100px;
  }

  .min-pad-left-right-10 {
    padding: 50px 10px;
    box-sizing: border-box;
  }


.content {
    display: none;
  }
}
</style>
